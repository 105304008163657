import styled from '@emotion/styled';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import { useAppContext } from '../../core/context';
import { useAppDispatch } from '../../store';
import { toggleSidebar } from '../../store/sidebar';
import { ActionIcon, Button, Loader, Menu, TextInput, Textarea } from '@mantine/core';
import { backend } from '../../core/backend';

const Container = styled.div`
    margin: calc(1.618rem - 1rem);
    margin-top: -0.218rem;
`;

const Task = styled.div`
    display: flex;
    align-items: center;
`
const Empty = styled.p`
    text-align: center;
    font-size: 0.8rem;
    padding: 2rem;
`;

const ChatList = styled.div``;

const ChatListItemLink = styled(Link)`
    display: block;
    position: relative;
    padding: 0.4rem 1rem;
    margin: 0.218rem 0;
    line-height: 1.7;
    text-decoration: none;
    border-radius: 0.25rem;

    &:hover, &:focus, &:active {
        background: rgba(0, 0, 0, 0.1);
    }

    &.selected {
        background: #2b3d54;
    }

    strong {
        display: block;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.6;
        padding-right: 1rem;
        color: white;
    }

    p {
        font-size: 0.8rem;
        font-weight: 200;
        opacity: 0.8;
    }

    .mantine-ActionIcon-root {
        position: absolute;
        right: 0.0rem;
        top: 50%;
        margin-top: -22px;
        opacity: 0;
    }

    &:hover {
        .mantine-ActionIcon-root {
            opacity: 1;
        }
    }
`;

function TasksListItem(props: { chat: any, onClick: any, selected: boolean }) {
    const c = props.chat;

    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setMenuOpen(open => !open);
    }, []);

    return (
        <ChatListItemLink to="">
            <strong>{c.task || <FormattedMessage defaultMessage={"Untitled"} description="default title for untitled chat sessions" />}</strong>
            {/* <Menu opened={menuOpen} 
                    closeOnClickOutside={true} 
                    closeOnEscape={true}
                    onClose={() => setMenuOpen(false)}>
                <Menu.Target>
                    <ActionIcon size="xl" onClick={toggleMenu}>
                        <i className="fas fa-ellipsis" />
                    </ActionIcon>
                </Menu.Target>
            </Menu> */}
        </ChatListItemLink>
    );
}

export default function Tasks(props: any) {
    const context = useAppContext();
    const dispatch = useAppDispatch();

    const currentChatID = context.currentChat.chat?.id;

    const onClick = useCallback((e: React.MouseEvent) => {
        if (e.currentTarget.closest('button')) {
            e.preventDefault();
            e.stopPropagation();
            return;
        }

        if (window.matchMedia('(max-width: 40em)').matches) {
            dispatch(toggleSidebar());
        }
    }, [dispatch]);

    useEffect(() => {
        if (currentChatID) {
            const el = document.querySelector(`[data-chat-id="${currentChatID}"]`);
            if (el) {
                el.scrollIntoView();
            }
        }
    }, [currentChatID]);

    const synced = !backend.current || backend.current?.isSynced();

    return (
        <Container>
            {props.tasks.length > 0 && <ChatList>
                {props.tasks.map(c => (
                    <Task>
                        {(c.in_progress && !c.in_done) ? <Loader size="sm" style={{flexBasis:"30px"}} /> : "-"}
                        <TasksListItem key={c.chatID} chat={c} onClick={onClick} selected={c.chatID === currentChatID} />
                    </Task>
                ))}
            </ChatList>}
            {props.tasks.length === 0 && !synced && <Empty>
                <Loader size="sm" variant="dots" />
            </Empty>}
            {props.tasks.length === 0 && synced && <Empty>
                <FormattedMessage defaultMessage={"No Tasks yet."} description="Message shown on the Chat History screen for new users who haven't started their first chat session" />
            </Empty>}
        </Container>
    );
}