import React, { useEffect } from 'react';
import { useAppContext } from '../core/context';
import Emitter from '../core/utils/eventEmitter';
import { useTTS } from "../core/tts/use-tts";
import { Modal, Button } from '@mantine/core';
import styled from "@emotion/styled";
import { FormattedMessage, useIntl } from "react-intl";

const FullContainer = styled.div`
display:flex;
align-items:center;
height: 100% !important;
// padding-left:50px;
background:#1c1c39;
`

const ContainerLeft = styled.div`


flex:auto;
background-position: center;
background-size: contain;
background-repeat: no-repeat;
z-index: 1;
display: flex;
align-items: center;
justify-content: center;
flex-basis:45%;

img{
    width:100%;
}
@media screen and (max-width: 767px) {
    display:none;
  }
`

const ContainerSU = styled.div`
    
    min-width: 300px;
    width: 55%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 6%;
    box-sizing: border-box;
    box-shadow: 10px 0 40px -10px #060606;
    background-image: url('../../../../assets/arrow-bg.png');
    background-position: right;
    background-size: cover;
    z-index: 2;
    
    * {
        font-family: "Work Sans", sans-serif;
        // color:#1b1b37;
    }

    h2 {
        font-size: 25px;
        font-weight: 600;
        color:#1b1b37;
        padding: 50px 0;
    }

    .color-white{
      color: white;
      width: auto;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      background-color: #1b1b37;
      padding: 1% 4%;
      font-size: 16px;
      margin-top: 1rem;
    }
    @media screen and (max-width: 767px) {
        width: 100%;
      }
`;



const apqBillingLink = "https://apq.ai/quinnai-billing-stats"

const BillPopup: any = () => {
  const context = useAppContext();
  const {cancel} = useTTS();

  const [show, setShow] = React.useState(false)
  const [showMarket, setShowMarket] = React.useState(false)
  const [authShow, setAuthShow] = React.useState(false)
  const [billShow, setBillShow] = React.useState(false)
  const [message, setMessage] = React.useState('');

  useEffect(() => {

    Emitter.on('error_bill', ()=>{
      setBillShow(true);
      // setMessage("pay your bill");
    })
    Emitter.on('error_bill_audio', ()=>{
        setBillShow(true);
        // setMessage("pay your bill");
        cancel();
      })
    Emitter.on('agent_Started', ()=>{
      alert('agetn has been started')
    })
    return () => {

    };
  },[context.chat,Emitter])

  useEffect(() => {

    Emitter.on('auth_error', (error)=>{
      setShow(true)
      setMessage(error.message)
    })

    Emitter.on('connection_error', (error)=>{
      setAuthShow(true)
        setMessage(error?.message)
    })

    Emitter.on('registration_success', (error)=>{
      setShowMarket(true)
      //setMessage(error.message)
    })

    return ()=>{

    }

  },[])


  const handleClick = () => {
    setShow(false);
  };
  const handleClickMarket = () => {
    setShowMarket(false);
  };
  const handleAuthClick = () => {
    setAuthShow(false);
  };
  const handleBillClick = () => {
    setBillShow(false);
  };

  const FullContainerAuth = styled.div`
  display:flex;
  align-items:center;
  justify-content:center;
  // height: 70vh;
  // width:70vw;
  // padding-left:50px;
  background:#bcbcc0;
  color:#1c1c39;
  flex-wrap:wrap;
  padding:10px;
  .infoSection{
    flex-basis: 195px;
    margin-bottom: 30px;
  }
  .infoExtraMargin{
    margin-right:30px;
  }
  @media screen and (max-width: 465px) {
  .subHeading br{
    display:none;
  }
  .infoExtraMargin{
    margin-right:0px;
  }
  .infoSection{
    flex-basis:100%;
    text-align:center;
  }
}
  `

  const FullContainerBill = styled.div`
  display:flex;
  align-items:stretch;
  justify-content:center;
  // height: 70vh;
  // width:70vw;
  // padding-left:50px;
  max-width:700px;
  background:transparent;
  color:#1c1c39;
  // flex-wrap:wrap;
  // padding:10px;
  .innerContainerRight{
    background:white;
    display:flex;
    align-items:stretch;
    justify-content:center;
    flex-basis:50%;
    flex-wrap:wrap;
  }
  .innerContainerLeft{
    flex-basis:50%;
    background-image: url('../../../../assets/Bill Due.png');
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .texContainer{
    margin-top:90px;
    display:flex;
    justify-content:start;
    padding-left:30px;
    flex-basis:100%;
    flex-wrap:wrap;
  }
  @media screen and (max-width: 767px) {
    .innerContainerLeft{
      display:none;
    }
    .innerContainerRight{
      flex-basis:100%;
      text-align:center;
    }
    .texContainer{
      justify-content:center;
      padding:0;
    }
  }
`
  return (
    <>
    <Modal zIndex={9999} padding={0} size={"width: 80vw"} opened={authShow} onClose={handleAuthClick} withCloseButton={false}>
    <FullContainerAuth>
    <p style={{fontSize:"12px",padding: "30px 0", fontWeight: "600", flexBasis:"100%", textAlign:"center"}}>APPLIED PHYSIC QUANTUM</p>
    <p style={{fontSize:"40px",padding: "0 0 30px 0", fontWeight: "700", flexBasis:"100%", textAlign:"center"}}>{message}</p>
    <img alt='Cogs' src="../../../../assets/Cogs.png" style={{maxWidth:"200px", padding:"0 0 20px 0"}}></img>
    <p className='subHeading' style={{fontSize:"20px",padding: "0 0 30px 0", fontWeight: "500", flexBasis:"100%", textAlign:"center",lineHeight: "35px",letterSpacing: "-1px"}}>We're giving our site a little extra polish,<br></br>but we'll be back better than ever soon!</p>
    <div className='infoSection infoExtraMargin'>
      <p style={{fontSize:"10px", fontWeight: "600",lineHeight: "18px", borderTop:"1px solid black"}}>In the meantime, reach us at:<br></br><span style={{color:"#5E17EB"}}>support@apq.ai</span></p>
    </div>
    <div className='infoSection'>
      <p style={{fontSize:"10px", fontWeight: "600",lineHeight: "18px", borderTop:"1px solid black"}}>We're also on:<br></br><span style={{color:"#5E17EB"}}><a href="https://www.instagram.com/quinn.apq.ai/" style={{textDecoration: "none"}}>Instagram</a>  |  <a href="https://www.facebook.com/profile.php?id=61559840276581" style={{textDecoration: "none"}}>Facebook</a>    |  <a href="https://x.com/quinn45586" style={{textDecoration: "none"}}>X (Twitter)</a></span></p>
    </div>
    </FullContainerAuth>
    </Modal>

    <Modal zIndex={9999} padding={0} size={"max-width:700px"} opened={billShow} onClose={handleBillClick} withCloseButton={false}>
      <FullContainerBill>
      {/* <img alt='Bill Due' src="../../../../assets/Bill Due.png" style={{maxWidth:"500px"}}></img> */}
      <div className='innerContainerLeft'>
      </div>
      <div className='innerContainerRight'>
        <p style={{textAlign:"center",padding:"40px 0", background:"#1c1c39", color:"white", width:"100%",fontSize: "14px",fontWeight: "500"}}>YOUR ATTENTION NEEDED!</p>
        <div className='texContainer'>
          <p style={{fontSize: "60px",fontFamily: "emoji", marginBottom:"30px"}}>Usage<br></br>Limit<br></br>Reached</p>
          <p style={{fontSize: "16px", marginBottom:"30px",flexBasis:"100%"}}>Your Pending Bill Urgently<br></br>Needs Attention! #actfast</p>
          <a href={apqBillingLink} target="_blank" rel="noopener noreferrer" onClick={handleBillClick} style={{fontSize: "16px",color:"white",background:"#1c1c39",padding:"15px 70px",borderRadius:"30px",marginBottom:"100px",cursor:"pointer",textDecoration: "none"}}>View Bill</a>
        </div>
      </div>
      </FullContainerBill>
    </Modal>

    <Modal opened={show} zIndex={9999} onClose={handleClick} centered>
     {message}
    </Modal>

    <Modal padding={0} fullScreen={true} opened={showMarket} zIndex={9999} onClose={handleClickMarket} centered withCloseButton={false}>
      <>
        <FullContainer>
          <ContainerLeft>
            <img src="../../../../assets/cubes.png" alt="" />
            
          </ContainerLeft>
          <ContainerSU>
            <h2>
              <FormattedMessage
                defaultMessage={
                  "Please verify your account via email and fill out the form below for a better experience."
                }
              />
            </h2>
            <div style={{ width: "100%", height: "100%" }}>
              <iframe
                width="100%"
                height="100%"
                src="https://forms.office.com/r/MCuGuUUsVU"
                style={{ border: "none", width: "100%", height: "100vh" }}
                allowFullScreen
              ></iframe>
            </div>
          </ContainerSU>
        </FullContainer>
      </>
        
    </Modal>

    </>
  );
};

export default BillPopup;