import styled from '@emotion/styled';
import { Button } from '@mantine/core';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch } from '../../store';
import { openOpenAIApiKeyPanel } from '../../store/settings-ui';
import { Page } from '../page';
import { useOption } from '../../core/options/use-option';
import { isProxySupported } from '../../core/chat/openai';
import capabilitiesData from './capabilites.json';
import examplesData from './examples.json';
import subHeadingData from './subHeadings.json';


const Container = styled.div`
    flex-grow: 1;
    padding-bottom: 5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Work Sans", sans-serif;
`;


export default function LandingPage(props: any) {
    const [openAIApiKey] = useOption<string>('openai', 'apiKey');
    const dispatch = useAppDispatch();
    const onConnectButtonClick = useCallback(() => dispatch(openOpenAIApiKeyPanel()), [dispatch]);
    const [darkmode] = useOption('dark-mode', 'dark-mode');
    const [randomCapabilities, setRandomCapabilities] = useState([]);
    const [randomExamples, setRandomExamples] = useState([]);
    const [randomSubHeading, setRandomSubHeading] = useState([]);



    useEffect(() => {
        const getRandomTexts = (texts:any, limit:Number) => {
          const shuffled = texts.sort(() => 0.5 - Math.random());
          return shuffled.slice(0, limit);
        };
    
        setRandomCapabilities(getRandomTexts(capabilitiesData.texts,3));
        setRandomExamples(getRandomTexts(examplesData.texts,3));
        setRandomSubHeading(getRandomTexts(subHeadingData.texts,1));

      }, []);

    return <Page id={'landing'} showSubHeader={true}>
        <Container>
        <div className='LPContainer'>
            <div className='LPTop'>
                <div className={`LPLogoText ${darkmode ? "lb":"db"}`}>QuinnAI</div>
                <div className={`LPText ${darkmode ? "color-white":"color-blue"}`}>{randomSubHeading}</div>
            </div>
            <div className='LPBottom'>
                <div className='LPCol'>
                    <div className='LPColInner'>
                        <img className='LPColImage' src={`${darkmode ? "../../../../assets/capabilities2White.png" : "../../../../assets/capabilities-blue.png"}`}></img>
                        <p className={`LPColHeading ${darkmode ? "whitecl":"bluecl"}`}>Capabilities</p>
                        {randomCapabilities.map((text, index) => (
                            <p key={index} className={`LPBubbleText ${darkmode ? 'black' : 'blue'}`}>{text}</p>
                        ))}
                    </div>
                    <div className='LPColInner'>
                        <img className='LPColImage' src={`${darkmode ? "../../../../assets/examples2White.png" : "../../../../assets/example-blue.png"}`}></img>
                        <p className={`LPColHeading ${darkmode ? "whitecl":"bluecl"}`}>Examples</p>
                        {randomExamples.map((text, index) => (
                            <p key={index} className={`LPBubbleText ${darkmode ? 'black' : 'blue'}`}>{text}</p>
                        ))}
                    </div>
                </div>
            </div>
        </div>
        </Container>
    </Page>;
}
