import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '.';
import sendLog from '../core/utils/sendLogs';

const initialState = {
    goalInput: '',
};

export const goalSlice = createSlice({
    name: 'goalInput',
    initialState,
    reducers: {
        setInputGoal: (state, action: PayloadAction<string>) => {
            //console.log("talking after this",state, action)
            sendLog(`talking after this, ${state}, ${action}`, 'INFO');
            state.goalInput = action.payload;
        },
    },
})

export const { setInputGoal } = goalSlice.actions;

export const selectInputGoal = (state: RootState) => state.goalInput.goalInput;

export default goalSlice.reducer;