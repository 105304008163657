import styled from '@emotion/styled';
import { useAppContext } from '../core/context';
import { Option } from '../core/options/option';
import { useOption } from '../core/options/use-option';
import { Button } from '@mantine/core';
import { useAppDispatch, useAppSelector } from '../store';
import { useCallback, useEffect } from 'react';
import { setTabAndOption } from '../store/settings-ui';

const Container = styled.div`
    margin: 0.5rem -0.5rem;

    display: flex;
    flex-wrap: wrap;
    text-align: left;

    justify-content: center;

    @media (min-width: 40em) {
        justify-content: flex-end;
    }

    .mantine-Button-root {
        font-size: 0.7rem;
        color: #999;
    }
`;

const TokenContainer = styled.div`
    display: flex;
    gap: 5px;
    align-items: center;
    font-size: 11px;
    color: #999;
    margin-right: 10px;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI emojiEmoji;
    font-weight: 500;
`
export function QuickSettingsButton(props: { groupID: string, option: Option }) {
    const context = useAppContext();
    const dispatch = useAppDispatch();

    const [value] = useOption(props.groupID, props.option.id, context.id || undefined);

    const onClick = useCallback(() => {
        dispatch(setTabAndOption({ tab: props.option.displayOnSettingsScreen, option: props.option.id }));
    }, [props.groupID, props.option.id, dispatch]);

    const labelBuilder = props.option.displayInQuickSettings?.label;
    let label = props.option.id;
    
    if (labelBuilder) {
        label = typeof labelBuilder === 'string' ? labelBuilder : labelBuilder(value, context.chat.options, context);
    }

    return (
        <Button variant="subtle" size="xs" compact onClick={onClick}>
            <span>
              
            {label === "gpt-3.5-turbo-0125" ? "GPT 3.5 Turbo" : label === "gpt-4" ? "GPT 4" : label === "dall-e-3" ? "DALL-E" : label === "gpt-4-turbo" ? "GPT 4 Turbo" : label === "gpt-4o" ? "GPT 4o" : label === "gpt-4o-mini" ? "GPT 4o Mini" : label}

            </span>
        </Button>
    )
}

export default function QuickSettings(props: any) {
    const context = useAppContext();
    const options = context.chat.getQuickSettings();

    useEffect(() => {
        props.additional.count()
    }, [])

    if (!options.length) {
        return <div style={{ height: '1rem' }} />;
    }

    return <Container>
        {
        props.additional.input !== null && props.additional.output !== null && 
            <TokenContainer>
                <span>Input: {props.additional.input}</span>
                <span>Output: {props.additional.output}</span>
            </TokenContainer>
        }
        {options.map(o => <QuickSettingsButton groupID={o.groupID} option={o.option} key={o.groupID + "." + o.option.id} />)}
    </Container>;
}