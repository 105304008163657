import React from 'react';

interface InfoPopupProps {
  handleClick: () => void;
  videoId: string;
}

const InfoPopup: React.FC<InfoPopupProps> = ({ handleClick, videoId }) => {
  return (
    <div
      style={{
        backgroundColor: '#245274c4',

        /* Fixed position */
        left: '0',
        position: 'fixed',
        top: 0,

        /* Take full size */
        height: '100%',
        width: '100%',

        /* Displayed on top of other elements */
        zIndex: 9999,

        display: 'flex',
        flexDirection: 'column',
        padding: '0%',
        overflow: 'hidden',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      onClick={handleClick}
    >
      <button onClick={handleClick} className={`iframe_button fa fa-times`}>
      </button>

      <iframe
        src={`https://www.youtube.com/embed/${videoId}`}
        width="80%"
        height="90%"
        className="iframe_video"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default InfoPopup;
