import EventEmitter from "events";
import SSE from "../utils/sse";
import { OpenAIMessage, Parameters } from "./types";
import { backend } from "../backend";
import sendLog from '../../core/utils/sendLogs';
import Emitter from "../utils/eventEmitter";


export const defaultModel = 'gpt-3.5-turbo-0125';

export function isProxySupported() {
    return true;
}

function shouldUseProxy(apiKey: string | undefined | null) {
    return !apiKey && isProxySupported();
}

function getEndpoint(proxied = false) {
    // return proxied ? '/chatapi/proxies/openai' : 'https://api.openai.com';
    return '/chatapi/proxies/openai';
}

export interface OpenAIResponseChunk {
    id?: string;
    done: boolean;
    choices?: {
        delta: {
            content: string;
        };
        img_url: string;
        index: number;
        finish_reason: string | null;
    }[];
    model?: string;
}

function parseResponseChunk(buffer: any): OpenAIResponseChunk {
    // console.log("data before chunk",buffer.toString())
    const chunk = buffer.toString().replace('data: ', '').trim();
    // console.log("data after chunk",buffer.toString().replace('data: ', '').trim())

    if (chunk === '[DONE]') {
        return {
            done: true,
        };
    }

    const parsed = JSON.parse(chunk);
    // console.log("json parsed chunk",parsed)

    // if (parsed.choices[0]?.delta?.hasOwnProperty("img_url")) {
    //     console.log("The key img_url is present in the JSON object.");
    // }

    return {
        id: parsed.id,
        done: false,
        choices: parsed.choices,
        model: parsed.model,
    };
}

export async function createChatCompletion(messages: OpenAIMessage[], parameters: Parameters): Promise<string> {
    const proxied = shouldUseProxy(parameters.apiKey);
    const endpoint = getEndpoint(true);

    if (!proxied && !parameters.apiKey) {
        // throw console.error("No API key provided")
        console.error("No API key provided")
        throw sendLog("No API key provided, async function createChatCompletion app -> src -> core -> chat -> openai.ts", 'INFO');
    }
    let payload = {
        "model": parameters.model,
        "messages": messages,
        "temperature": parameters.temperature,
        "chatId": parameters.chatId,
        "getTitle": parameters.getTitle
        
          
    };

    // The GPT-4V model preview requires max tokens to be set
    if (parameters.model === "gpt-4-turbo" || parameters.model === "gpt-4o" || parameters.model === "gpt-4o-mini") {
        payload["max_tokens"] = 4096;
    }

    const response = await fetch(endpoint + '/v1/chat/completions', {
        method: "POST",
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Authorization': !proxied ? `Bearer ${parameters.apiKey}` : '',
            'Content-Type': 'application/json',
        },
        // body: JSON.stringify({
        //     "model": parameters.model,
        //     "messages": messages,
        //     "temperature": parameters.temperature,
        //     // "max_tokens": parameters?.tokens, commented for now
        //     "chatId": parameters.chatId,
        //     "getTitle": parameters.getTitle
        // }),
        body: JSON.stringify(payload),
    });

    // console.log("the response is : ",response);

    // if(response.status==402){
    //     alert("Please pay your outstanding bill to cotinue");
    //     return null
    // }

    const data = await response.json();

    return data.choices[0].message?.content?.trim() || '';
}

export async function whsiperAutioTranscription(audioData: any): Promise<string> {
    const endpoint = '/chatapi/proxies/openal/whisper';

    const file = new File([audioData], "audio.mp3", { type: "audio/mp3" });

    const formData = new FormData();
    formData.append("file", file);
    const response = await fetch(endpoint, {
        method: "POST",
        headers: {
            'Accept': 'application/json, text/plain, */*'
        },
        body: formData,
    });
    if(response.status==402){
        Emitter.emit('error_bill',"pay your bill");
        return "";
    }

    const data = await response.json();

    return data
}

export async function createStreamingChatCompletion(messages: OpenAIMessage[], parameters: Parameters) {
    const emitter = new EventEmitter();

    const proxied = shouldUseProxy(parameters.apiKey);
    const endpoint = getEndpoint(proxied);

    
    if (!proxied) {
        //throw console.error("No API key provided")
        console.log("No API key provided")
        throw sendLog("No API key provided, async function createStreamingChatCompletion app -> src -> core -> chat -> openai.ts", 'INFO');
    }
    let payload = {
        "model": parameters.model,
        "messages": messages,
        "temperature": parameters.temperature,
        "stream": true,
        "chatId": parameters.chatId
        
    };

    // The GPT-4V model preview requires max tokens to be set
    if (parameters.model === "gpt-4-turbo"|| parameters.model === "gpt-4o"|| parameters.model === "gpt-4o-mini") {
        payload["max_tokens"] = 4096;
    }

    const eventSource = new SSE(endpoint + '/v1/chat/completions', {
        method: "POST",
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Authorization': !proxied ? `Bearer ${parameters.apiKey}` : '',
            'Content-Type': 'application/json',
        },
        // payload: JSON.stringify({
        //     "model": parameters.model,
        //     "messages": messages,
        //     "temperature": parameters.temperature,
        //     // "max_tokens": parameters?.tokens, commented for now
        //     "stream": true,
        //     "chatId": parameters.chatId
        // }),
        payload: JSON.stringify(payload),
    }) as SSE;

    let contents = '';



    eventSource.addEventListener('error', (event: any) => {
        if (!contents) {
            let error = event.data;
            try {
                error = JSON.parse(error).error.message;
            } catch (e) {}
            emitter.emit('error', error);
        }
    });

    eventSource.addEventListener('message', async (event: any) => {
        // console.log("event name",event);
        if (event.data === '[DONE]') {
            emitter.emit('done');
            return;
        }

        try {
            const chunk = parseResponseChunk(event.data);
            if (chunk.choices && chunk.choices.length > 0) {
                if (chunk.choices[0]?.img_url) {
                    // console.log("The key img_url is present in the JSON object.",chunk.choices[0]?.img_url);
                    emitter.emit('aiImage', chunk.choices[0]?.img_url);
                    // emitter.emit('data', chunk.choices[0]?.delta?.content);

                }
                else{
                    console.log("chunk data",chunk.choices)
                    contents += chunk.choices[0]?.delta?.content || '';
                    console.log("chunk contents",chunk.choices[0]?.delta?.content)
                    emitter.emit('data', contents);
                }
            }
        } catch (e) {
            console.error(e);
            sendLog(`eventSource.addEventListener Function try and catch app -> src -> core -> chat -> openai.ts: ${JSON.stringify(e)}`, 'ERROR');
        }
    });

    eventSource.stream();

    return {
        emitter,
        cancel: () => eventSource.close(),
    };
}

export const maxTokensByModel = {
    "gpt-3.5-turbo-0125": 16385,
    "gpt-4": 8192,
    "dall-e-3": 4000,
    "gpt-4-turbo": 128000,
    "gpt-4o": 128000,
    "gpt-4o-mini": 128000,
    

}