import { defaultModel } from "../core/chat/openai";
import { OptionGroup } from "../core/options/option-group";

export const parameterOptions: OptionGroup = {
    id: 'parameters',
    options: [
        {
            id: "model",
            defaultValue: defaultModel,
            resettable: false,
            scope: "user",
            displayOnSettingsScreen: "chat",
            displayAsSeparateSection: true,
            displayInQuickSettings: {
                name: "Show Model",
                displayByDefault: true,
                label: (value) => value,
            },
            renderProps: (value, options, context) => ({
                type: "select",
                label: "Model",
                description: 'Changing an AI model will start a new chat conversation. The existing chat conversation is saved and can be accessed in the Chat History.',
                disabled: false,
                options: [
                    {
                        label: "GPT 3.5 Turbo (default)",
                        value: "gpt-3.5-turbo-0125",
                    },
                    {
                        label: "GPT 4",
                        value: "gpt-4",
                    },
                    {
                        label: "DALL-E",
                        value: "dall-e-3",
                    },
                    {
                        label: "GPT 4 Turbo",
                        value: "gpt-4-turbo",
                    },
                    {
                        label: "GPT 4o",
                        value: "gpt-4o",
                    },
                    {
                        label: "GPT 4o Mini",
                        value: "gpt-4o-mini",
                    },
                   
                ],
            }),
        },
        {
            id: "temperature",
            defaultValue: 0.5,
            resettable: true,
            scope: "chat",
            displayOnSettingsScreen: "chat",
            displayAsSeparateSection: true,
            displayInQuickSettings: {
                name: "Show Temperature",
                displayByDefault: false,
                label: (value) => "Temperature: " + value.toFixed(1),
            },
            renderProps: (value, options, context) => ({
                type: "slider",
                label: "Temperature: " + value.toFixed(1),
                min: 0,
                max: 1,
                step: 0.1,
                description: context.intl.formatMessage({ defaultMessage: "The temperature parameter controls the randomness of the AI's responses. Lower values will make the AI more predictable, while higher values will make it more creative." }),
            })
        }
    ]
};

export const availableModels = [
    "gpt-3.5-turbo-0125",
    "gpt-4",
    "dall-e-3",
    "gpt-4-turbo",
    "gpt-4o",
    "gpt-4o-mini",
];
