import EventEmitter from 'events';
import * as Y from 'yjs';
import { encode, decode } from '@msgpack/msgpack';
import { MessageTree } from './chat/message-tree';
import { Chat } from './chat/types';
import { AsyncLoop } from "./utils/async-loop";
import { ChatManager } from '.';
import { getRateLimitResetTimeFromResponse } from './utils';
import { importChat } from './chat/chat-persistance';
import sendLog from './utils/sendLogs';
import Emitter from './utils/eventEmitter';

const endpoint = '/chatapi';

export let backend: {
    current?: Backend | null
} = {};

export interface User {
    id: string;
    email?: string;
    name?: string;
    avatar?: string;
    services?: string[];
}

export class Backend extends EventEmitter {
    public user: User | null = null;
    public services: string[] = [];
    private checkedSession = false;

    private sessionInterval = new AsyncLoop(() => this.getSession(), 1000 * 30);
    private syncInterval = new AsyncLoop(() => this.sync(), 1000 * 5);

    private pendingYUpdate: Uint8Array | null = null;
    private lastFullSyncAt = 0;
    private legacySync = false;
    private rateLimitedUntil = 0;

    public constructor(private context: ChatManager) {
        super();
        this.initialize();

        if ((window as any).AUTH_PROVIDER) {
            backend.current = this;

            this.sessionInterval.start();
            this.syncInterval.start();
        }
    }
    async initialize() {
        sendLog('Backend constructor called', 'INFO');
    }

    public isSynced() {
        return (this.checkedSession && !this.isAuthenticated) || this.lastFullSyncAt > 0;
    }

    public async getSession() {
        if (Date.now() < this.rateLimitedUntil) {
            // console.log(`Waiting another ${this.rateLimitedUntil - Date.now()}ms to check session due to rate limiting.`);
            sendLog(`Waiting another ${this.rateLimitedUntil - Date.now()}ms to check session due to rate limiting.`, 'INFO');
            return;
        }

        const wasAuthenticated = this.isAuthenticated;
        const session = await this.get(endpoint + '/session');
        console.log("session resp", JSON.stringify(session));

        if(session.success===false){
            const cookiesToDelete = ['session_id', 'apq_user_email', 'apq_user_first_name', 'apq_user_last_name'];
            const domain = '.apq.ai';  // Replace with your actual domain

            // Delete cookies for the specified domain
            cookiesToDelete.forEach((cookieName) => {
                document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${domain};`;
            });

            // Delete cookies for localhost (no domain specified)
            cookiesToDelete.forEach((cookieName) => {
                document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
            });
            return
        }

        if(session?.authConnError){
            Emitter.emit('connection_error', session)
        }

        if (session?.authProvider) {
            (window as any).AUTH_PROVIDER = session.authProvider;
        }

        if (session?.authenticated) {
            this.user = {
                id: session.userID,
                email: session.email,
                name: session.name,
                avatar: session.picture,
                services: session.services,
            };
            this.services = session.services || [];
        } else {
            this.user = null;
            this.services = session?.services || [];
        }

        this.checkedSession = true;

        if (wasAuthenticated !== this.isAuthenticated) {
            this.emit('authenticated', this.isAuthenticated);
            this.lastFullSyncAt = 0;
        }
    }

    public async loginUser({username,password,age,captcha}:{username:string,password:string,age:string,captcha:string}){
        const data = {
            username,
            password,
            captcha,
            request_type: 'ajax'
        }

        if(!username || !password || !captcha){
            const errorResp = {message: "All fields are required", success: false}
            Emitter.emit("auth_error", errorResp)
            return errorResp
        }
        if(age.length !== 0){
            const errorResp = {message: "All fields are required", success: false}
            Emitter.emit("auth_error", errorResp)
            return errorResp
        }

        const response = await this.post(endpoint + '/login', data);
        // alert(JSON.stringify(response));

        if(!response.success) {
            if(response.authApiError) Emitter.emit('connection_error', response)
            else Emitter.emit("auth_error", response)
        }

        if(response?.success) {
            this.getSession()
        }

        return response;
    }

    public async registerUser({firstName,lastName,email,password,age,captcha}:{firstName:string,lastName:string,email:string,password:string,age:string,captcha:string}){
        const data = {
            firstName,
            lastName,
            email,
            password,
            captcha,
            request_type: 'ajax'
        }
        // alert(`${firstName},${lastName},${email},${password},${captcha}`)
        if(!firstName || !lastName || !email || !password || !captcha){
            const errorResp = {message: "All fields are required", success: false}
            Emitter.emit("auth_error", errorResp)
            return errorResp
        }
        if(age.length !== 0){
            const errorResp = {message: "All fields are required", success: false}
            Emitter.emit("auth_error", errorResp)
            return errorResp
        }

        const response = await this.post(endpoint + '/register', data);

        if(!response.success) {
            if(response.authApiError) Emitter.emit('connection_error', response)
            else Emitter.emit("auth_error", response)
        }

        if(response.success) Emitter.emit('registration_success', response)

        return response;
    }

    public async logoutUser(){
        try{await this.post(endpoint + '/logout', {})}
        catch(e){}
        
        window.location.reload()
    }

    public async sync() {
        if (!this.isAuthenticated) {
            return;
        }

        if (Date.now() < this.rateLimitedUntil) {
            //console.log(`Waiting another ${this.rateLimitedUntil - Date.now()}ms before syncing due to rate limiting.`);
            sendLog(`Waiting another ${this.rateLimitedUntil - Date.now()}ms before syncing due to rate limiting.`, 'INFO');
            return;
        }

        const encoding = await import('lib0/encoding');
        const decoding = await import('lib0/decoding');
        const syncProtocol = await import('y-protocols/sync');

        const sinceLastFullSync = Date.now() - this.lastFullSyncAt;

        const pendingYUpdate = this.pendingYUpdate;
        if (pendingYUpdate && pendingYUpdate.length > 4) {
            this.pendingYUpdate = null;

            const encoder = encoding.createEncoder();
            syncProtocol.writeUpdate(encoder, pendingYUpdate);

            const response = await fetch(endpoint + '/y-sync', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/octet-stream'
                },
                body: encoding.toUint8Array(encoder),
            });

            if (response.status === 429) {
                this.rateLimitedUntil = getRateLimitResetTimeFromResponse(response);
            }
        } else if (sinceLastFullSync > 1000 * 60 * 1) {
            this.lastFullSyncAt = Date.now();

            const encoder = encoding.createEncoder();
            syncProtocol.writeSyncStep1(encoder, this.context.doc.root);

            const queue: Uint8Array[] = [
                encoding.toUint8Array(encoder),
            ];

            for (let i = 0; i < 4; i++) {
                if (!queue.length) {
                    break;
                }

                const buffer = queue.shift()!;

                const response = await fetch(endpoint + '/y-sync', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/octet-stream'
                    },
                    body: buffer,
                });

                if (!response.ok) {
                    this.rateLimitedUntil = getRateLimitResetTimeFromResponse(response);
                    throw new Error(response.statusText);
                }

                const responseBuffer = await response.arrayBuffer();
                const responseChunks = decode(responseBuffer) as Uint8Array[];

                for (const chunk of responseChunks) {
                    if (!chunk.byteLength) {
                        continue;
                    }

                    const encoder = encoding.createEncoder();
                    const decoder = decoding.createDecoder(chunk);

                    const messageType = decoding.readVarUint(decoder);
                    decoder.pos = 0;

                    syncProtocol.readSyncMessage(decoder, encoder, this.context.doc.root, 'sync');

                    if (encoding.length(encoder)) {
                        queue.push(encoding.toUint8Array(encoder));
                    }
                }
            }

            this.context.emit('update');
        }

        if (!this.legacySync) {
            this.legacySync = true;

            const chats = await this.get(endpoint + '/legacy-sync');

            this.context.doc.transact(() => {
                for (const chat of chats) {
                    try {
                        importChat(this.context.doc, chat);
                    } catch (e) {
                        console.error(e);
                        sendLog(`this.context.doc.transact Function try and catch app -> src -> core -> backend.ts: ${JSON.stringify(e)}`, 'ERROR');
                    }
                }
            });
        }
    }

    public receiveYUpdate(update: Uint8Array) {
        if (!this.pendingYUpdate) {
            this.pendingYUpdate = update;
        } else {
            this.pendingYUpdate = Y.mergeUpdates([this.pendingYUpdate, update]);
        }
    }

    async signIn() {
        window.location.href = endpoint + '/login';
    }

    get isAuthenticated() {
        return this.user !== null;
    }

    async logout() {
        window.location.href = endpoint + '/logout';
    }

    async shareChat(chat: Chat): Promise<string | null> {
        try {
            const { id } = await this.post(endpoint + '/share', {
                ...chat,
                messages: chat.messages.serialize(),
            });
            if (typeof id === 'string') {
                return id;
            }
        } catch (e) {
            console.error(e);
            sendLog(`async shareChat Function try and catch app -> src -> core -> backend.ts: ${JSON.stringify(e)}`, 'ERROR');
        }
        return null;
    }

    async getSharedChat(id: string): Promise<Chat | null> {
        const format = process.env.REACT_APP_SHARE_URL || (endpoint + '/share/:id');
        const url = format.replace(':id', id);
        try {
            const chat = await this.get(url);
            if (chat?.messages?.length) {
                chat.messages = new MessageTree(chat.messages);
                return chat;
            }
        } catch (e) {
            console.error(e);
            sendLog(`async getSharedChat Function try and catch app -> src -> core -> backend.ts: ${JSON.stringify(e)}`, 'ERROR');
        }
        return null;
    }

    async deleteChat(id: string) {
        if (!this.isAuthenticated) {
            return;
        }

        return this.post(endpoint + '/delete', { id });
    }

    async startGoal(goal: string, model: string) {
        // if (!this.isAuthenticated) {
        //     console.log("user not authenticated")
        //     return;
        // }

        return this.post(endpoint + '/proxies/autonomous', { goal, model, action: 'START' });
    }

    async analyzeGoal(goal: string, task: string, model: string) {
        if (!this.isAuthenticated) {
            return;
        }

        try{
            return this.post(endpoint + '/proxies/autonomous', { goal, task, model, action: 'ANALYZE' });
        }catch(e){
            return {
                text: `Sorry, I'm unable to analyze this task: ${e}`
            }
        }
    }

    async executeGoal(goal: string, task: string, analysis: { action: string, arg: string, reasoning: string }, model: string) {
        if (!this.isAuthenticated) {
            return;
        }

        try{
            return this.post(endpoint + '/proxies/autonomous', { goal, task, analysis, model, action: 'EXECUTE' });
        }catch(e){
            return {
                text: `Sorry, I'm unable to execute this task with the following details agent provided: ${e}`
            }
        }
    }
    
    async createNewTask(goal: string, tasks: [], completedTasks:string[], last_task:string, result:string  , model: string) {
        if (!this.isAuthenticated) {
            return;
        }
        try{
            return this.post(endpoint + '/proxies/autonomous', { goal, tasks, completedTasks, last_task, result, model, action: 'CREATE' });
        } catch(e) {
            return {
                text: `Sorry, I'm unable to execute this task with the following details agent provided: ${e}`
            }
        }
    }

    async get(url: string) {
        const response = await fetch(url, {
            credentials: 'include'
        });
        // if (response.status === 408) {
        //     alert("error 408");
        //     throw new Error(response.statusText);
        // }
        if (response.status === 429) {
            this.rateLimitedUntil = getRateLimitResetTimeFromResponse(response);
        }
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        return response.json();
    }

    async post(url: string, data: any) {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        // if (response.status === 408) {
        //     alert("error 408");
        //     throw new Error(response.statusText);
        // }
        if (response.status === 429) {
            this.rateLimitedUntil = getRateLimitResetTimeFromResponse(response);
        }
        if (!response.ok) {
            throw new Error(response.statusText);
        }

        try{
            return response.json();
        }catch(e){
            return response.text() 
        }
    }

    // public async sendLog(logString: string, logType: 'INFO' | 'WARN' | 'ERROR' | 'DEBUG' = 'INFO') {
    //     const data = await this.post(endpoint +'/track', {
    //         message: logString,
    //         type: logType
    //     });
    //     if (data.message === 'success') {
    //         console.log(logString);
    //         console.log('Log sent successfully');
    //     } else {
    //         console.log(logString);
    //         console.error('Error sending log');
    //     }
    //  }
}