import styled from '@emotion/styled';
import { Button, ActionIcon, Textarea, Loader, Popover } from '@mantine/core';
import { getHotkeyHandler, useHotkeys, useMediaQuery } from '@mantine/hooks';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppContext } from '../core/context';
import { useAppDispatch, useAppSelector } from '../store';
import { selectMessage, setMessage } from '../store/message';
import { selectSettingsTab, openOpenAIApiKeyPanel } from '../store/settings-ui';
import { speechRecognition, supportsSpeechRecognition } from '../core/speech-recognition-types'
import { useWhisper } from '@chengsokdara/use-whisper';
import QuickSettings from './quick-settings';
import { useOption } from '../core/options/use-option';
import { maxTokensByModel, whsiperAutioTranscription } from '../core/chat/openai';
import sendLog from '../core/utils/sendLogs';
import { encoding_for_model } from 'tiktoken'
import { set } from '../core/utils/idb';

const Container = styled.div`
    // background: rgb(45,45,45);
    border-top: thin solid #393933;
    padding: 1rem 1rem 0 1rem;

    .inner {
        max-width: 50rem;
        margin: auto;
        text-align: right;
    }

    .inner textarea {
        // background: rgb(70,70,70);
        border-radius: 9px;
    }
    .inner .mantine-InputWrapper-root{
        border-radius: 9px;
    }

    .settings-button {
        margin: 0.5rem -0.4rem 0.5rem 1rem;
        font-size: 0.7rem;
        color: #999;
    }
`;

export declare type OnSubmit = (name?: string) => Promise<boolean>;

export interface MessageInputProps {
    disabled?: boolean;
}

export default function MessageInput(props: MessageInputProps) {
    const message = useAppSelector(selectMessage);
    const context = useAppContext();
    const [recording, setRecording] = useState(false);
    const [speechError, setSpeechError] = useState<string | null>(null);
    const [imageUrl, setImageUrl] = useState<any | null>(null);
    const [isImageUploading, setIsImageUploading] = useState(false);
    const [uploadedImageName, setUploadedImageName] = useState('');
    const [showImageNameDropdown, setShowImageNameDropdown] = useState(false);
    const hasVerticalSpace = useMediaQuery('(min-height: 1000px)');
    const [useOpenAIWhisper] = useOption<boolean>('speech-recognition', 'use-whisper');
    const [openAIApiKey] = useOption<string>('openai', 'apiKey');
    const [whisperRecordingBlob, setWhisperRecordingBlob] = useState<Blob | null>(null);
    const [isFlying, setFlying] = useState(false);
    const [darkmode] = useOption('dark-mode', 'dark-mode');
    const [inputTokens, setInputTokens] = useState<number | null>(0);
    const [outputTokens, setOutputTokens] = useState<number | null>(0);
    const isUserTyping = useRef<any>()
    const [initialMessage, setInitialMessage] = useState('');
    const {
        transcribing,
        transcript,
        startRecording,
        stopRecording
    } = useWhisper({
        apiKey: openAIApiKey || ' ',
        streaming: false,
        onTranscribe: async (transcript: Blob) => {
            const text: any = await whsiperAutioTranscription(transcript);
            return {text: text?.text}
        }
    });


    const countTokens = async (text: string) => {
        if (isUserTyping.current) {
            clearTimeout(isUserTyping.current);
          }
          const model:any = context.chat.options.getOption<string>('parameters', 'model', context.currentChat.chat?.id)

          if(model !== 'dall-e-3' && model !== 'gpt-4-turbo' && model !== 'gpt-4-vision-preview' && model !== 'gpt-4o' && model !== 'gpt-4o-mini'){
      
            // Start a new timeout
            isUserTyping.current = setTimeout(() => {
                // Execute your function after 500ms of inactivity
                // const model:any = context.chat.options.getOption<string>('parameters', 'model', context.currentChat.chat?.id)
                console.warn(model)
                const tikinstance = encoding_for_model(model)
                const modelToken = maxTokensByModel[model]
                const openaiTokenCounter = tikinstance.encode(text)
                const inputAssump = openaiTokenCounter.length
                const outputAssump = inputAssump ? modelToken - inputAssump : modelToken  
                if(inputAssump && (outputAssump?.toString() !== 'NaN' && inputAssump?.toString() !== 'NaN')){
                    console.warn(inputAssump, outputAssump)
                    setInputTokens(inputAssump)
                    setOutputTokens(outputAssump)
                }else{
                    countTokens(text)
                }
            }, 400);
        }else{
            setOutputTokens(null)
            setInputTokens(null)
        }
    }

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const fileInputRef = useRef<HTMLInputElement>(null);

    const tab = useAppSelector(selectSettingsTab);

    const [showMicrophoneButton] = useOption<boolean>('speech-recognition', 'show-microphone');
    const [submitOnEnter] = useOption<boolean>('input', 'submit-on-enter');

    const onChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {        
        const model:any = context.chat.options.getOption<string>('parameters', 'model', context.currentChat.chat?.id)
        const modelToken = maxTokensByModel[model === 'gpt-3.5-turbo-0125' ? 'chat'+model : model]
        if(e.target.value.length >= modelToken){
            console.warn(e.target.value.length, modelToken)
            dispatch(setMessage(e.target.value.substring(0, modelToken)))
            countTokens(e.target.value.substring(0, modelToken))
            return
        }
        dispatch(setMessage(e.target.value));
        countTokens(e.target.value)
        // check if user still typing 
    }, [dispatch]);

    const pathname = useLocation().pathname;

    const onSubmit = useCallback(async () => {
        setFlying(true);
        setSpeechError(null);
        const emptyUserConfig: { messageType: string; isReply: boolean; messageId: string; parentId: string } = {
            messageType: '',
            isReply: false,
            messageId: '',
            parentId: ''
        };
        if(!message){
            alert('To proceed, a text message must be added to the chat.');
                return;
        }
        const id = await context.onNewMessage(message, emptyUserConfig , imageUrl);
        console.log("id image" + imageUrl);

        if (id) {
            if (!window.location.pathname.includes(id)) {
                navigate('/chat/' + id);
            }
            dispatch(setMessage(''));
            setImageUrl(null);
        }
        setUploadedImageName ('');
    }, [context, message, dispatch, imageUrl, navigate]);

    const onSpeechError = useCallback((e: any) => {
        //console.error('speech recognition error', e);
        sendLog(`speech recognition error from (\app\src\components\input.tsx) :${JSON.stringify(e)}`, 'ERROR');
        
        setSpeechError(e.message);

        try {
            speechRecognition?.stop();
        } catch (e) {
        }

        try {
            stopRecording();
        } catch (e) { }

        setRecording(false);
    }, [stopRecording]);

    const onHideSpeechError = useCallback(() => setSpeechError(null), []);

    const onSpeechStart = useCallback(async () => {
        let granted = false;
        let denied = false;

        try {
            const result = await navigator.permissions.query({ name: 'microphone' as any });
            if (result.state == 'granted') {
                granted = true;
            } else if (result.state == 'denied') {
                denied = true;
            }
        } catch (e) { }

        if (!granted && !denied) {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ video: false, audio: true });
                stream.getTracks().forEach(track => track.stop());
                granted = true;
            } catch (e) {
                denied = true;
            }
        }

        if (denied) {
            onSpeechError(new Error('speech permission was not granted'));
            return;
        }

        try {
            if (!recording) {
                setRecording(true);

                if (useOpenAIWhisper || !supportsSpeechRecognition) {
                    // console.log("inside if useOpenAIWhisper");
                    // if (!openAIApiKey) {
                    //     dispatch(openOpenAIApiKeyPanel());
                    //     return false;
                    // }
                    // recorder.start().catch(onSpeechError);
                    setInitialMessage(message);
                    await startRecording();
    

                } else if (speechRecognition) {
                    // console.log("inside if speechRecognition");
                    const initialMessage = message;

                    speechRecognition.continuous = true;
                    speechRecognition.interimResults = true;

                    speechRecognition.onresult = (event) => {
                        let transcript = '';
                        for (let i = 0; i < event.results.length; i++) {
                            if (event.results[i].isFinal && event.results[i][0].confidence) {
                                transcript += event.results[i][0].transcript;
                            }
                        }
                        dispatch(setMessage(initialMessage + ' ' + transcript));
                    };

                    speechRecognition.start();
                } else {
                    onSpeechError(new Error('not supported'));
                }
            } else {
                if (useOpenAIWhisper || !supportsSpeechRecognition) {
                    await stopRecording();
                    setTimeout(() => setRecording(false), 500);
                } else if (speechRecognition) {
                    speechRecognition.stop();
                    setRecording(false);
                } else {
                    onSpeechError(new Error('not supported'));
                }
            }
        } catch (e) {
            onSpeechError(e);
        }
    }, [recording, message, dispatch, onSpeechError, setInitialMessage, openAIApiKey]);

    useEffect(() => {
        if (useOpenAIWhisper || !supportsSpeechRecognition) {
            if (!transcribing && !recording && transcript?.text) {
                dispatch(setMessage(initialMessage + ' ' + transcript.text));
            }
        }
    }, [initialMessage, transcript, recording, transcribing, useOpenAIWhisper, dispatch]);

    useEffect(()=>{
        context.chat.options.on('update', ()=>{
            countTokens(message)
        })
    },[])

    useHotkeys([
        ['n', () => document.querySelector<HTMLTextAreaElement>('#message-input')?.focus()]
    ]);

    const blur = useCallback(() => {
        document.querySelector<HTMLTextAreaElement>('#message-input')?.blur();
    }, []);
    // const calculate_image_tokens = (width, height) => {
    //     if (width > 2048 || height > 2048) {
    //         const aspect_ratio = width / height;
    //         if (aspect_ratio > 1) {
    //             width = 2048;
    //             height = Math.floor(2048 / aspect_ratio);
    //         } else {
    //             width = Math.floor(2048 * aspect_ratio);
    //             height = 2048;
    //         }
    //     }
    
    //     if (width >= height && height > 768) {
    //         width = Math.floor((768 / height) * width);
    //         height = 768;
    //     } else if (height > width && width > 768) {
    //         width = 768;
    //         height = Math.floor((768 / width) * height);
    //     }
    
    //     const tiles_width = Math.ceil(width / 512);
    //     const tiles_height = Math.ceil(height / 512);
    //     const total_tokens = 85 + 170 * (tiles_width * tiles_height);
    
    //     return total_tokens;
    // };

    const onImageSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const maxSizeMB = 10;
            const fileSizeInMB = file.size / (1024 * 1024); // Convert bytes to MB
            
            if (fileSizeInMB > maxSizeMB) {
                alert('File size exceeds 10 MB limit');
                return;
            }
            setIsImageUploading(true);
            setUploadedImageName(file.name);
            const reader = new FileReader();
    
            reader.onload = (loadEvent) => {
                const base64Image = loadEvent.target?.result as string; // Type assertion
                if (base64Image) {
                    setImageUrl(base64Image); // Update the state with the base64 image data
    
                    // Create a new image element
                    const image = new Image();
                    image.onload = () => {
                        setIsImageUploading(false);
                        // console.log("Image uploaded: ", imageUrl);
    
                        // Calculate image tokens
                        // const width = image.width;
                        // const height = image.height;
                        // const totalTokens = calculate_image_tokens(width, height);
                        // console.log("Total tokens for image: ", totalTokens);
                        // Use totalTokens as needed
                    };
                    image.src = base64Image; // Set the image source to base64 data
                } else {
                    // Handle the case where loadEvent.target.result is null
                    console.error("Failed to upload image: loadEvent.target.result is null.");
                }
            };
    
            reader.onerror = (error) => {
                console.error('Error uploading image: ', error);
                setIsImageUploading(false);
                setUploadedImageName('');
            };
    
            reader.readAsDataURL(file); // Read the file as a data URL
        }
    };
    

    function handleMouseEnter() {
        if (imageUrl) {
            setShowImageNameDropdown(true);
        }
    }

    function handleMouseLeave() {
        setShowImageNameDropdown(false);
    }
    const smodel = context.chat.options.getOption<string>('parameters', 'model', context.currentChat.chat?.id);
    const rightSection = useMemo(() => {
        return (
            <div style={{
                opacity: '0.8',
                paddingRight: '0.5rem',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                width: '100%',
            }}>
                {context.generating && (<>
                    <Button variant="subtle" size="xs" compact onClick={() => {
                        context.chat.cancelReply(context.currentChat.chat?.id, context.currentChat.leaf!.id);
                    }}>
                        <FormattedMessage defaultMessage={"Cancel"} description="Label for the button that can be clicked while the AI is generating a response to cancel generation" />
                    </Button>
                    <Loader size="xs" style={{ padding: '0 0.8rem 0 0.5rem' }} />
                </>)}
                {!context.generating && (
                    <>
                        {showMicrophoneButton && <Popover width={200} position="bottom" withArrow shadow="md" opened={speechError !== null}>
                            <Popover.Target>
                                <ActionIcon size="xl"
                                    onClick={onSpeechStart}>
                                    {transcribing && <Loader size="xs" />}
                                    <div className='micButtonContainer'>
                                    {!transcribing && 
                                        (
                                        recording && !transcribing ?
                                            <FormattedMessage defaultMessage={"Stop"} description="Label for the button that can be clicked while the your voice is being recorded" />
                                        :
                                            <i className={`fa fa-microphone ${darkmode ? "":"fa-blue"}`} style={{ fontSize: '90%', color: recording ? 'red' : '' }} />
                                        )
                                    }
                                    </div>
                                </ActionIcon>
                            </Popover.Target>
                            <Popover.Dropdown>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                }}>
                                    <p style={{
                                        fontFamily: `"Work Sans", sans-serif`,
                                        fontSize: '0.9rem',
                                        textAlign: 'center',
                                        marginBottom: '0.5rem',
                                    }}>
                                        Sorry, an error occured trying to record audio.
                                    </p>
                                    <Button variant="light" size="xs" fullWidth onClick={onHideSpeechError}>
                                        Close
                                    </Button>
                                </div>
                            </Popover.Dropdown>
                        </Popover>}
                        <input
                            type="file"
                            accept=".jpg,.jpeg,.png"
                            style={{ display: 'none' }}
                            onChange={onImageSelected}
                            ref={fileInputRef}
                        />

                        {/* <ActionIcon size="xl"
                            onClick={() => fileInputRef.current.click()}
                            disabled={isImageUploading}>
                            {isImageUploading ? (
                                <i className="fa fa-ellipsis-h" style={{ fontSize: '90%' }} />
                            ) : (
                                <i className="fa fa-camera" style={{ fontSize: '90%' }} />
                            )}
                        </ActionIcon> */}
                       

                         {(smodel === 'gpt-4-turbo' || smodel === 'gpt-4o' || smodel === 'gpt-4o-mini') && (
                            <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                <Popover width={200} position="bottom" withArrow shadow="md" opened={showImageNameDropdown}>
                                    <Popover.Target>
                                        <ActionIcon
                                            size="xl"
                                            onClick={() => fileInputRef.current && fileInputRef.current.click()}
                                            disabled={isImageUploading}
                                        >
                                            <div className='micButtonContainer'>
                                            {isImageUploading ? (
                                                <i className="fa fa-ellipsis-h" style={{ fontSize: '90%' }} />
                                            ) : uploadedImageName ? (
                                                <i className="fa fa-check" style={{ fontSize: '90%' }} />
                                            ) : (
                                                <i className="fa fa-camera" style={{ fontSize: '90%' }} />
                                            )}
                                            </div>
                                        </ActionIcon>
                                    </Popover.Target>
                                    <Popover.Dropdown>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                        }}>
                                            <p style={{
                                                fontFamily: `"Work Sans", sans-serif`,
                                                fontSize: '0.9rem',
                                                textAlign: 'center',
                                                marginBottom: '0.5rem',
                                            }}>
                                                {uploadedImageName}
                                            </p>
                                        </div>
                                    </Popover.Dropdown>
                                </Popover>
                            </div>
                        )}
                        <ActionIcon size="xl"
                            onClick={onSubmit}
                            disabled={isImageUploading}>
                            <i className={`fa fa-paper-plane ${isFlying ? 'flying' : ''} ${darkmode ? "":"fa-blue"}`} style={{ fontSize: '90%' }} />
                        </ActionIcon>
                    </>
                )}
            </div>
        );
    }, [recording, transcribing, isImageUploading, imageUrl, showImageNameDropdown, onSubmit, onSpeechStart, props.disabled, context.generating, speechError, onHideSpeechError, showMicrophoneButton, uploadedImageName, isFlying]);

    const disabled = context.generating;

    const isLandingPage = pathname === '/';
    if (context.isShare || (!isLandingPage && !context.id)) {
        return null;
    }

    const hotkeyHandler = useMemo(() => {
        const keys = [
            ['Escape', blur, { preventDefault: true }],
            ['ctrl+Enter', onSubmit, { preventDefault: true }],

        ];
        if (submitOnEnter) {
            keys.unshift(['Enter', onSubmit, { preventDefault: true }]);
        }
        const handler = getHotkeyHandler(keys as any);
        return handler;
    }, [onSubmit, blur, submitOnEnter]);

    return <Container className={`${darkmode ? "black45":"blue"}`}>
        <div className="inner">
            <Textarea disabled={props.disabled || disabled}
                id="message-input"
                // style={{
                //     backgroundColor: "rgb(70,70,70) !important"
                // }}
                className={darkmode ? "black70":"white"}
                autosize
                minRows={(hasVerticalSpace || context.isHome) ? 3 : 2}
                maxRows={12}
                placeholder={intl.formatMessage({ defaultMessage: "Enter a message here..." })}
                value={message}
                onChange={onChange}
                rightSection={rightSection}
                rightSectionWidth={context.generating ? 100 : 55}
                onKeyDown={hotkeyHandler} />
            <QuickSettings key={tab} additional={{
                input:inputTokens,
                output: outputTokens,
                count: () => {
                    countTokens(message)
                },
            }} />
        </div>
    </Container>;
}
