import { ttsPlugins } from "../core/plugins/metadata";
import { OptionGroup } from "../core/options/option-group";

const ttsPluginMetadata = ttsPlugins.map(p => new p().describe());

export const ttsServiceOptions: OptionGroup = {
    id: 'tts',
    options: [
        {
            id: 'autoplay',
            displayOnSettingsScreen: "speech",
            defaultValue: false,
            displayAsSeparateSection: true,
            renderProps : (value, options, context)=> ({
                type: "checkbox",
                label: "Read messages aloud automatically",
                description: context.intl.formatMessage({ defaultMessage: "By enabling this option all of the responses will be played aloud through a text to speech provider of your choosing" }),
            }),
        },
        {
            id: 'service',
            displayOnSettingsScreen: "speech",
            defaultValue: "elevenlabs",
            displayAsSeparateSection: true,
            renderProps: (value, options, context)=> ({
                type: "select",
                label: "Choose a Text-to-Speech Provider",
                options: ttsPluginMetadata.map(p => ({
                    label: p.name,
                    value: p.id,
                })),
                description: context.intl.formatMessage({ defaultMessage: "Choose between which service you want to utilize to perform Text to Speech You can choose between: Your Browser's Built-ln Text-to-Speech which is free to use or ElevenLabs Text-to-Speech" }),
            }),
        },
    ],
}