import Emitter from "../utils/eventEmitter";
const endpoint = '/chatapi';

export default class AgentCore{
    chatId: string
    constructor(chatId:string | any){
        this.chatId = chatId
    }

    async startGoal(goal: string, model: string) {
        return this.post(endpoint + '/proxies/autonomous', { goal, model, action: 'START' });
    }

    async analyzeGoal(goal: string, task: string, model: string) {

        try{
            return this.post(endpoint + '/proxies/autonomous', { goal, task, model, action: 'ANALYZE' });
        }catch(e){
            return {
                text: `Sorry, I'm unable to analyze this task: ${e}`
            }
        }
    }

    async executeGoal(goal: string, task: string, analysis: { action: string, arg: string, reasoning: string }, model: string) {
        try{
            return this.post(endpoint + '/proxies/autonomous', { goal, task, analysis, model, action: 'EXECUTE' });
        }catch(e){
            return {
                text: `Sorry, I'm unable to execute this task with the following details agent provided: ${e}`
            }
        }
    }
    
    async createNewTask(goal: string, tasks: [], completedTasks:string[], last_task:string, result:string  , model: string) {

        try{
            return this.post(endpoint + '/proxies/autonomous', { goal, tasks, completedTasks, last_task, result, model, action: 'CREATE' });
        }catch(e){
            return {
                text: `Sorry, I'm unable to execute this task with the following details agent provided: ${e}`
            }
        }
    }

    async get(url: string) {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        return response.json();
    }

    async post(url: string, data: any) {
        data.chatId = this.chatId
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        if (!response.ok) {
            if(response.status==402){
                Emitter.emit('error_bill',"pay your bill");
                return [];
            }
            throw new Error(response.statusText);
        }

        try{
            return response.json();
        }catch(e){
            return response.text() 
        }
    }

}