import EventEmitter from "events";
import { createChatCompletion, createStreamingChatCompletion } from "./openai";
import { PluginContext } from "../plugins/plugin-context";
import { pluginRunner } from "../plugins/plugin-runner";
import { Chat, Message, OpenAIMessage, Parameters, getOpenAIMessageFromMessage, getTextContentFromOpenAIMessageContent } from "./types";
import { EventEmitterAsyncIterator } from "../utils/event-emitter-async-iterator";
import { YChat } from "./y-chat";
import { OptionsManager } from "../options";
import sendLog from '../../core/utils/sendLogs';
import Emitter from "../utils/eventEmitter";

export class ReplyRequest extends EventEmitter {
    private mutatedMessages: OpenAIMessage[];
    private mutatedParameters: Parameters;
    private lastChunkReceivedAt: number = 0;
    private timer: any;
    private done: boolean = false;
    private content = '';
    private img_url = '';
    private cancelSSE: any;
    private messageType: any;
    private messageContent: string;
    public error: boolean;
    public errorMessage: string;
    constructor(private chat: Chat,
                private yChat: YChat,
                private messages: Message[],
                private replyID: string,
                private requestedParameters: Parameters,
                private pluginOptions: OptionsManager,
                messageType: string,
                messageContent: string) {
        super();
        this.mutatedMessages = [...messages];
        this.mutatedMessages = messages.map(m => getOpenAIMessageFromMessage(m));
        this.mutatedParameters = { ...requestedParameters };
        delete this.mutatedParameters.apiKey;

        this.messageType = messageType;
        this.messageContent = messageContent;
        this.error = false;
        this.errorMessage = '';
    }

    pluginContext = (pluginID: string) => ({
        getOptions: () => {
            return this.pluginOptions.getAllOptions(pluginID, this.chat.id);
        },

        getCurrentChat: () => {
            return this.chat;
        },

        createChatCompletion: async (messages: OpenAIMessage[], _parameters: Parameters) => {
            return await createChatCompletion(messages, {
                ..._parameters,
                apiKey: this.requestedParameters.apiKey,
                chatId: this.chat.id
            });
        },

        setChatTitle: async (title: string) => {
            this.yChat.title = title;
        },
    } as PluginContext);

    private scheduleTimeout() {
        this.lastChunkReceivedAt = Date.now();

        clearInterval(this.timer);

        this.timer = setInterval(() => {
            const sinceLastChunk = Date.now() - this.lastChunkReceivedAt;
            if (sinceLastChunk > 60000 && !this.done) {
                this.onError('no response from OpenAI in the last 30 seconds');
            }
        }, 2000);
    }

    public async execute() {
        try {
            this.scheduleTimeout();

            await pluginRunner("preprocess-model-input", this.pluginContext, async plugin => {
                const output = await plugin.preprocessModelInput(this.mutatedMessages, this.mutatedParameters);
                this.mutatedMessages = output.messages;
                this.mutatedParameters = output.parameters;
                this.lastChunkReceivedAt = Date.now();
            });
            

            if(this.messageType === "agent"){
                this.content = this.messageContent
                sendLog(`if message type === agent ${this.content}`, 'INFO');

                await this.onDone()

                return;
            }

            const { emitter, cancel } = await createStreamingChatCompletion(this.mutatedMessages, {
                ...this.mutatedParameters,
                apiKey: this.requestedParameters.apiKey,
                chatId: this.chat.id
            });
            this.cancelSSE = cancel;

            const eventIterator = new EventEmitterAsyncIterator<string>(emitter, ["data", "done", "error","aiImage"]);

            for await (const event of eventIterator) {
                const { eventName, value } = event;

                switch (eventName) {
                    case 'data':
                        await this.onData(value);
                        break;

                    case 'aiImage':
                        await this.onImage(value);
                        break;

                    case 'done':
                        await this.onDone();
                        break;

                    case 'error':
                        if (!this.content || !this.done) {
                            await this.onError(value);
                        }
                        break;
                }
            }
        } catch (e: any) {
            console.error(e);
            sendLog(`async execute Function try and catch app -> src -> core -> chat -> create-reply.ts: ${JSON.stringify(e)}`, 'ERROR');
            this.onError(e.message);
        }
    }

    public async onData(value: any) {
        if (this.done) {
            return;
        }

        this.lastChunkReceivedAt = Date.now();

        this.content = value;

        await pluginRunner("postprocess-model-output", this.pluginContext, async plugin => {
            const output = await plugin.postprocessModelOutput({
                role: 'assistant',
                content: this.content,
            }, this.mutatedMessages, this.mutatedParameters, false);

            // this.content = output.content;
            this.content = getTextContentFromOpenAIMessageContent(output.content);
        });

        this.yChat.setPendingMessageContent(this.replyID, this.content);
    }

    public async onImage(value: any) {
        if (this.done) {
            return;
        }

        // this.yChat.messages.get(this.replyID).role = "user";
        this.yChat.changeRole(this.replyID, 'user');
        this.yChat.setQuinnImg(this.replyID, true);

        this.lastChunkReceivedAt = Date.now();

        this.img_url = value;

        this.yChat.setImageUrl(this.replyID, this.img_url);

    }

    public async onDone() {
        if (this.done) {
            return;
        }
        clearInterval(this.timer);
        this.lastChunkReceivedAt = Date.now();
        this.done = true;
        this.emit('done');

        this.yChat.onMessageDone(this.replyID);

        await pluginRunner("postprocess-model-output", this.pluginContext, async plugin => {

            const output = await plugin.postprocessModelOutput({
                role: 'assistant',
                content: this.content,
            }, this.mutatedMessages, this.mutatedParameters, true);

            if (typeof output.content === 'string') {
                this.content = output.content; // Assign the string directly
            } else if (Array.isArray(output.content)) {
                // If it's an array, join its elements into a single string
                this.content = output.content.join(' ');
            } else {
                // Handle other cases accordingly (e.g., if it's neither a string nor an array)
                console.error('Unexpected type for output.content:', typeof output.content);
            }
            

        });

        this.yChat.setMessageContent(this.replyID, this.content);
    }

    public async onError(error: string) {
        if (this.done) {
            return;
        }
        this.done = true;
        this.emit('done');
        clearInterval(this.timer);
        this.cancelSSE?.();

        interface MyError {
            success: boolean;
            message: string;
            // Add other properties if needed
        }

        let jsonError: MyError;

        try {
            jsonError = JSON.parse(error);
            if (typeof jsonError !== 'object' || jsonError === null) {
                throw new Error('Parsed JSON is not an object');
            }
        } catch (e) {
            // Handle parsing error
            console.error('Error parsing JSON:', e);
            return false;
        }

        if ('success' in jsonError && 'message' in jsonError && jsonError.success === false) {
            Emitter.emit('error_bill', jsonError);
            this.yChat.onMessageDone(this.replyID);
        }else {
            this.content += `\n\nI'm sorry, I'm having trouble connecting to OpenAI (${error || 'no response from the API'}). Please make sure you've entered your OpenAI API key correctly and try again.`;
            this.content = this.content.trim();

            this.yChat.setMessageContent(this.replyID, this.content);
            this.yChat.onMessageDone(this.replyID);
        }
    }

    public onCancel() {
        clearInterval(this.timer);
        this.done = true;
        this.yChat.onMessageDone(this.replyID);
        this.cancelSSE?.();
        this.emit('done');
    }

    // private setMessageContent(content: string) {
    //     const text = this.yChat.content.get(this.replyID);
    //     if (text && text.toString() !== content) {
    //         text?.delete(0, text.length);
    //         text?.insert(0, content);
    //     }
    // }
}