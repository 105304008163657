import styled from "@emotion/styled";
import { Button, Modal, PasswordInput, TextInput } from "@mantine/core";
import { useCallback, useState, useRef, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useAppDispatch, useAppSelector } from "../store";
import { closeModals, openLoginModal, openSignupModal, selectModal } from "../store/ui";
import { useAppContext } from "../core/context";
import ReCAPTCHA from 'react-google-recaptcha'

const FullContainer = styled.div`
display:flex;
align-items:center;
height: 100vh;
// padding-left:50px;
background:#1c1c39;
`

const ContainerRight = styled.div`

height: 100%;
flex:auto;
background-image: url('../../../../assets/Signup-right.png');
background-position: center;
background-size: cover;
z-index: 1;
`

const ContainerLeft = styled.div`

height: 100%;
flex:auto;
background-image: url('../../../../assets/cubes.png');
background-position: center;
background-size: contain;
background-repeat: no-repeat;
z-index: 1;
display: flex;
align-items: center;
justify-content: center;
flex-basis:45%;

img{
    width:540px;
}
@media screen and (max-width: 767px) {
    display:none;
  }
`
const Container = styled.form`
    height: 100%;
    min-width: 300px;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 5%;
    box-sizing: border-box;
    box-shadow: 10px 0 40px -10px #060606;
    z-index: 2;
    
    * {
        font-family: "Work Sans", sans-serif;
        color:white;
    }

    h2 {
        font-size: 1.5rem;
        font-weight: bold;
    }

    .mantine-TextInput-root, .mantine-PasswordInput-root {
        margin-top: 1rem;
    }
    .mantine-PasswordInput-input input{
        width:100%;
    }
    
    .mantine-TextInput-root + .mantine-Button-root,
    .mantine-PasswordInput-root + .mantine-Button-root {
        margin-top: 1.618rem;
    }

    .mantine-Button-root {
        margin-top: 1rem;
    }

    label {
        margin-bottom: 0.25rem;
    }
    .signin-logo{
        width: 230px;
        margin: 0 auto;
    }
    .age-field{
        display:none;
    }
    .captcha-field{
        margin: 20px auto;
    }
    .submit-button{
        background: linear-gradient(90deg, #5170ff 60%, #ff66c4)!important;
        border-radius: 50px !important;
        height: 45px !important;
    }
    a.forgot-password-btn {
        width: fit-content;
        align-self: end;
        margin-top: 10px;
        font-size: 12px;
        text-decoration: none;
    }
    button svg path{
        color:#3498db;
    }
    @media screen and (max-width: 767px) {
        width: 100%;
      }
`;

const ContainerSU = styled.form`
    height: 100%;
    min-width: 300px;
    width: 55%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 15%;
    box-sizing: border-box;
    box-shadow: 10px 0 40px -10px #060606;
    background-image: url('../../../../assets/arrow-bg.png');
    background-position: right;
    background-size: cover;
    z-index: 2;
    
    * {
        font-family: "Work Sans", sans-serif;
        // color:#1b1b37;
    }

    h2 {
        font-size: 40px;
        font-weight: 500;
        color:#1b1b37;
    }

    .mantine-TextInput-root, .mantine-PasswordInput-root {
        margin-top: 1rem;
    }
    .mantine-PasswordInput-input input{
        width:100%;
    }
    
    .mantine-TextInput-root + .mantine-Button-root,
    .mantine-PasswordInput-root + .mantine-Button-root {
        margin-top: 1.618rem;
    }

    .mantine-Button-root {
        margin-top: 1rem;
    }

    label {
        margin-bottom: 0.25rem;
        color:#1b1b37;
    }
    input{
        color:white;
    }
    .color-blue{
        color:#1b1b37;
    }
    .signin-logo{
        width: 230px;
        margin: 0 auto;
    }
    .submit-button{
        background: #1b1b37;
        border-radius: 50px !important;
        height: 45px !important;   
        width:50%; 
        margin: 0 auto;
    }
    .age-field{
        display:none;
    }
    .captcha-field{
        margin: 20px auto;
    }
    .submit-button span{
        color:white;
    }  
    button svg path{
        color:#3498db;
    }
    @media screen and (max-width: 767px) {
        width: 100%;
      }
`;

export function LoginModal(props: any) {
    const modal = useAppSelector(selectModal);
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const ctx = useAppContext();
    const [username, setUsername] = useState('');
    const [age, setAge] = useState('');
    const [password, setPassword] = useState('');
    const recaptcha = useRef<ReCAPTCHA>(null);
    const [refreshKey, setRefreshKey] = useState(0);

    async function submitForm(event) {
        event.preventDefault();
        const captcha = recaptcha.current.getValue();
        ctx.backend.loginUser({ username, password, age, captcha });
        setRefreshKey(prevKey => prevKey + 1);
    }

    const onClose = useCallback(() => dispatch(closeModals()), [dispatch]);
    const onCreateAccountClick = useCallback(() => dispatch(openSignupModal()), [dispatch]);
    const [isCookieAccepted, setIsCookieAccepted] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 950);

    useEffect(() => {
        const customString = /FusionGPT-IOSAPP/i.test(navigator.userAgent);

        if (customString) {
            setIsCookieAccepted(true);
        } else {
            const cookiesAccepted = localStorage.getItem('cookiesAccepted');
            setIsCookieAccepted(cookiesAccepted === 'true');
        }

    }, []);

    const handleAcceptCookies = () => {
        localStorage.setItem('cookiesAccepted', 'true');
        setIsCookieAccepted(true);
    };

    const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 950);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Modal padding={0} fullScreen={true} opened={!ctx.authenticated} onClose={() => {}} withCloseButton={false} key={refreshKey}> {/* add key prop */}
            <FullContainer>
                <Container onSubmit={submitForm}>
                    <img className="signin-logo" alt='FusionGPT logo' src="../../../../assets/logo-signup.png"></img>
                    <h2>
                        <FormattedMessage defaultMessage={"Sign in"} />
                    </h2>
                    <input type="hidden" name="redirect_url" value={window.location.href} />
                    <TextInput
                        label={intl.formatMessage({ defaultMessage: "Email address" })}
                        name="username"
                        placeholder={intl.formatMessage({ defaultMessage: "Enter your email address" })}
                        type="email"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                    <TextInput
                        className="age-field"
                        style={{ margin: 0 }}
                        name="age"
                        placeholder="Enter your age"
                        type="text"
                        value={age}
                        onChange={(e) => setAge(e.target.value)}
                    />
                    <PasswordInput
                        label={intl.formatMessage({ defaultMessage: "Password" })}
                        name="password"
                        placeholder={intl.formatMessage({ defaultMessage: "Enter your password" })}
                        maxLength={500}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <a className="forgot-password-btn" href="https://apq.ai/forgot-password" target="_blank">Forgot Password</a>
                    <ReCAPTCHA className="captcha-field" ref={recaptcha} sitekey="6LfU34ApAAAAADbSySbNkzuNEp0mzX3FbcTIG9UU" />
                    <Button className="submit-button" fullWidth type="submit">
                        <FormattedMessage defaultMessage={"Sign in"} />
                    </Button>
                    <Button fullWidth variant="subtle" onClick={onCreateAccountClick}>
                        <FormattedMessage defaultMessage={"Or create an account"} description="Label for a button on the Sign In page that lets the user create an account instead" />
                    </Button>
                </Container>
                <ContainerRight></ContainerRight>
                <div id="cookie-consent" style={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    backgroundColor: '#333',
                    color: '#fff',
                    padding: '15px',
                    textAlign: 'center',
                    zIndex: 99,
                    height: 'auto',
                    display: isCookieAccepted ? 'none' : 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: isSmallScreen ? '0' : '66px',
                    flexWrap: 'wrap',
                    marginTop: '20px',
                    fontSize: isSmallScreen ? '0.7em' : '1em',
                    lineHeight: isSmallScreen ? '20px' : 'inherit',
                }}>
                    <p style={{ margin: '0' }}>
                        <FormattedMessage defaultMessage="Applied Physics Quantum uses cookies to ensure you get the best experience. By continuing to use the site, you consent to the use of cookies." />
                    </p>
                    <button id="accept-cookies" style={{
                        backgroundColor: '#fff',
                        color: '#333',
                        padding: isSmallScreen ? '2px 25px' : '2px 12px',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        transition: 'background-color 0.3s ease',
                        fontSize: isSmallScreen ? '0.9em' : '1em',
                    }} onClick={handleAcceptCookies}>
                        <FormattedMessage defaultMessage="Okay" />
                    </button>
                </div>
            </FullContainer>
        </Modal>
    );
}

export function CreateAccountModal(props: any) {
    const modal = useAppSelector(selectModal);
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const ctx = useAppContext();
    const recaptcha = useRef<ReCAPTCHA>(null);


    const [firstName,setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [age, setAge] = useState('')
    const [password, setPassword] = useState('')
    

    const onClose = useCallback(() => dispatch(closeModals()), [dispatch]);
    const onSignInClick = useCallback(() => dispatch(openLoginModal()), [dispatch]);
    async function submitForm(event) {
        event.preventDefault()
        const captcha = recaptcha.current.getValue()
          ctx.backend.registerUser({firstName,lastName,email,password,age,captcha})
      }

    return <Modal padding={0} fullScreen={true} opened={modal === 'signup'} onClose={onClose} withCloseButton={false}>
        <FullContainer>
        <ContainerLeft>
            {/* <img src="../../../../assets/cubes.png" alt="" /> */}
        </ContainerLeft>
        <ContainerSU>
            <h2>
                <FormattedMessage defaultMessage={"Create an account"} />
            </h2>
            <input type="hidden" name="redirect_url" value={window.location.href} />
            <TextInput
                label={intl.formatMessage({ defaultMessage: "First name" })}
                name="firstName" 
                placeholder={intl.formatMessage({ defaultMessage: "Enter your first name" })}
                type="text"
                value={firstName}
                onChange={(e)=>setFirstName(e.target.value)}
                required
            />
            <TextInput
                label={intl.formatMessage({ defaultMessage: "Last name" })}
                name="lastName" 
                placeholder={intl.formatMessage({ defaultMessage: "Enter your last name" })}
                type="text"
                value={lastName}
                onChange={(e)=>setLastName(e.target.value)}
                required
            />
            <TextInput
                label={intl.formatMessage({ defaultMessage: "Email address" })}
                name="username"
                placeholder={intl.formatMessage({ defaultMessage: "Enter your email address" })}
                type="email"
                value={email}
                onChange={(e)=>setEmail(e.target.value)}
                required />
            <TextInput
                className="age-field"
                style={{margin:0}}
                name="age"
                placeholder="enter your age"
                type="text"
                value={age}
                onChange={(e)=>setAge(e.target.value)}
                 />
            <PasswordInput
                className="pass-input"
                label={intl.formatMessage({ defaultMessage: "Password" })}
                name="password"
                placeholder={intl.formatMessage({ defaultMessage: "Enter your password" })}
                minLength={6}
                maxLength={500}
                value={password}
                onChange={(e)=>setPassword(e.target.value)}
                required />
            <ReCAPTCHA className="captcha-field" ref={recaptcha} sitekey="6LfU34ApAAAAADbSySbNkzuNEp0mzX3FbcTIG9UU" />
            <Button className="submit-button" fullWidth onClick={submitForm}>
                <FormattedMessage defaultMessage={"Sign up"} />
            </Button>
            <Button className="color-blue" fullWidth variant="subtle" onClick={onSignInClick}>
                <FormattedMessage defaultMessage={"Or sign in to an existing account"} description="Label for a button on the Create Account page that lets the user sign into their existing account instead" />
            </Button>
        </ContainerSU>
       
        </FullContainer>
    </Modal>
}