import { Voice } from "../core/tts/types";
import TTSPlugin from "../core/tts/tts-plugin";
import { PluginDescription } from "../core/plugins/plugin-description";

export interface OpenAIPluginOptions {
    apiKey: string | null;
    voice: string;
}

/**
 * Plugin for integrating with OpenAI's Text-to-Speech service.
 */
export default class OpenAITTSPlugin extends TTSPlugin<OpenAIPluginOptions> {
    static voices: Voice[] = [
        { service: "openai-tts", id: "echo", name: "Echo" },
        { service: "openai-tts", id: "fable", name: "Fable" },
        { service: "openai-tts", id: "onyx", name: "Onyx" },
        { service: "openai-tts", id: "nova", name: "Nova" },
        { service: "openai-tts", id: "shimmer", name: "Shimmer" },
        { service: "openai-tts", id: "alloy", name: "Alloy" },

        // Add more voices as needed
    ];

    // private endpoint = "https://api.openai.com/v1/audio/speech";
    private endpoint = "/chatapi/proxies/openaitts";


    describe(): PluginDescription {
        return {
            id: "openai-tts",
            name: "OpenAI Text-to-Speech",
            options: [
                {
                    id: "apiKey",
                    defaultValue: null,
                    displayOnSettingsScreen: "speech",
                    displayAsSeparateSection: true,
                    renderProps: (value, options, context) => ({
                        type: "nothing",
                        description: <></>,
                        hidden: options.getOption('tts', 'service') !== 'elevenlabs',
                    }),
                },
                {
                    id: "voice",
                    defaultValue: OpenAITTSPlugin.voices[0].id,
                    displayOnSettingsScreen: "speech",
                    displayAsSeparateSection: true,
                    renderProps: (value, options, context) => ({
                        type: "select",
                        label: "Voice",
                        options: OpenAITTSPlugin.voices.map(v => ({
                            label: v.name!,
                            value: v.id,
                        })),
                        hidden: options.getOption('tts', 'service') !== 'openai-tts',
                    }),
                },
            ],
        };
    }

    async speakToBuffer(text: string, voice?: Voice): Promise<ArrayBuffer | null> {
        if (!voice) {
            voice = await this.getCurrentVoice();
        }

        const url = this.endpoint + '/v1/text-to-speech-open-ai/' + voice.id;

        const response = await fetch(url, {
            method: 'POST',
            headers: this.createHeaders(),
            body: JSON.stringify({
                "model": "tts-1-hd",
                "input":text,
                voice: voice.id,
            }),
        });

        if (response.ok) {
            return await response.arrayBuffer();
        } else {
            console.error("Error:", response.statusText);
            return null;
        }
    }

    private createHeaders(): Record<string, string> {
        const headers: Record<string, string> = {
            'Content-Type': 'application/json'
        };
        return headers;
    }

    async getCurrentVoice(): Promise<Voice> {
        const voiceID = this.options?.voice;
        return OpenAITTSPlugin.voices.find(v => v.id === voiceID) || OpenAITTSPlugin.voices[0];
    }

    async initialize() {
        // Initialize any necessary state or fetch data if needed
    }
}
